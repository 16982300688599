@import url('@scania/tegel/dist/tegel/tegel.css');
@import url('./reset.css');

:root {
  --header-height: 64px;
  --banner-height: 68px;
  --header-and-banner-height: calc(var(--header-height) + var(--banner-height));
}

.banner-header {
    position: fixed;
    top: 0;
    width: 100%;
}

.container {
    padding-top: var(--header-and-banner-height);
}

.container.no-banner {
    padding-top: var(--header-height);
}


.side-menu {
    position: sticky;
    top: calc(var(--header-and-banner-height));
    height: calc(100vh - var(--header-and-banner-height));
}

.side-menu.no-banner {
    top: var(--header-height);
    height: calc(100vh - var(--header-height));
}

tds-side-menu-close-button {
  display: none;
}

main {
    flex: 1;
    text-align: center;
    margin: auto;
}

@media (max-width: 992px) {
  .side-menu {
    position: fixed;
    top: var(--banner-height);
  }
  .side-menu.no-banner {
    top: 0;
  }
  tds-side-menu-close-button {
    display: block;
  }
}


